import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import SmsBlast from '../../model/SmsBlast'
import PaginationInfo from '../../model/PaginationInfo'
import {
  SmsBlastsActions,
  CreateSmsBlastAction,
  DeleteSmsBlastAction,
  FetchSmsBlastsAction,
  FetchSmsBlastByIDAction,
  rejectCreateSmsBlast,
  RejectCreateSmsBlastAction,
  rejectDeleteSmsBlast,
  RejectDeleteSmsBlastAction,
  rejectFetchSmsBlasts,
  RejectFetchSmsBlastsAction,
  rejectFetchSmsBlastByID,
  RejectFetchSmsBlastByIDAction,
  rejectUpdateSmsBlast,
  RejectUpdateSmsBlastAction,
  resolveCreateSmsBlast,
  ResolveCreateSmsBlastAction,
  resolveDeleteSmsBlast,
  ResolveDeleteSmsBlastAction,
  resolveFetchSmsBlasts,
  ResolveFetchSmsBlastsAction,
  resolveFetchSmsBlastByID,
  ResolveFetchSmsBlastByIDAction,
  resolveUpdateSmsBlast,
  ResolveUpdateSmsBlastAction,
  UpdateSmsBlastAction,
  SendSmsBlastAction,
  resolveSendSmsBlast,
  rejectSendSmsBlast,
  ResolveSendSmsBlastAction,
  RejectSendSmsBlastAction,
} from '../actions/smsBlasts'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForSmsBlastPage } from '../selectors/smsBlasts'

interface SmsBlastsReducerState {
  byID: Record<number, SmsBlast>
  pages: { [hash: string]: SmsBlastsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  errorByID: { [id: number]: Error | null }
  successFlashMessage: string | null
}

export class SmsBlastsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: SmsBlastsReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  errorByID:           {},
  successFlashMessage: null,
}

export default (state: SmsBlastsReducerState = initialState, action: SmsBlastsActions): SmsBlastsReducerState | Loop<SmsBlastsReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_SMS_BLASTS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchSmsBlastsAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new SmsBlastsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForSmsBlastPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.smsBlasts.getSmsBlasts, {
              successActionCreator: resolveFetchSmsBlasts,
              failActionCreator:    rejectFetchSmsBlasts,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchSmsBlastsAction
          const { smsBlasts, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForSmsBlastPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       smsBlasts.map((smsBlast: SmsBlast) => smsBlast.id),
            paginationInfo: paginationInfo,
          }

          // Map smsBlast ids to smsBlasts
          let idMappedSmsBlasts: Record<number, SmsBlast> = {}
          smsBlasts.forEach(smsBlast => {
            idMappedSmsBlasts[smsBlast.id] = smsBlast
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedSmsBlasts),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchSmsBlastsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForSmsBlastPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_SMS_BLAST_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchSmsBlastByIDAction
          const { smsBlastID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [smsBlastID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [smsBlastID]: null,
              },
            },
            Cmd.run(API.smsBlasts.getSmsBlastByID, {
              successActionCreator: resolveFetchSmsBlastByID,
              failActionCreator:    rejectFetchSmsBlastByID,
              args:                 [smsBlastID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchSmsBlastByIDAction
          const { smsBlast } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [smsBlast.id]: smsBlast,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [smsBlast.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchSmsBlastByIDAction
          const { error, requestParams } = payload
          const { smsBlastID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [smsBlastID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [smsBlastID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_SMS_BLAST: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateSmsBlastAction
          const { smsBlastID, filters, title, body, recipientIDs } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [smsBlastID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [smsBlastID]: null,
              },
            },
            Cmd.run(API.smsBlasts.putSmsBlast, {
              successActionCreator: resolveUpdateSmsBlast,
              failActionCreator:    rejectUpdateSmsBlast,
              args:                 [smsBlastID, filters, title, body, recipientIDs],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateSmsBlastAction
          const { smsBlast } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [smsBlast.id]: smsBlast,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [smsBlast.id]: false,
              },
              successFlashMessage: 'SMS Blast successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateSmsBlastAction
          const { error, requestParams } = payload
          const { smsBlastID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [smsBlastID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [smsBlastID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_SMS_BLAST: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteSmsBlastAction
          const { smsBlastID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [smsBlastID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [smsBlastID]: null,
              },
            },
            Cmd.run(API.smsBlasts.deleteSmsBlast, {
              successActionCreator: resolveDeleteSmsBlast,
              failActionCreator:    rejectDeleteSmsBlast,
              args:                 [smsBlastID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteSmsBlastAction
          const { smsBlast } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[smsBlast.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [smsBlast.id]: false,
              },
              successFlashMessage: 'SMS Blast successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/sms-blasts')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteSmsBlastAction
          const { error, requestParams } = payload
          const { smsBlastID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [smsBlastID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [smsBlastID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_SMS_BLAST: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateSmsBlastAction
          const { filters, title, body, recipientIDs } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [-1]: null,
              },
            },
            Cmd.run(API.smsBlasts.postSmsBlast, {
              successActionCreator: resolveCreateSmsBlast,
              failActionCreator:    rejectCreateSmsBlast,
              args:                 [filters, title, body, recipientIDs],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateSmsBlastAction
          const { smsBlast } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [smsBlast.id]: smsBlast,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [smsBlast.id]: false,
              },
              successFlashMessage: 'SMS Blast successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/sms-blasts/${smsBlast.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateSmsBlastAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.SEND_SMS_BLAST: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as SendSmsBlastAction
          const { smsBlastID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [smsBlastID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [smsBlastID]: null,
              },
            },
            Cmd.run(API.smsBlasts.postSmsBlastSend, {
              successActionCreator: resolveSendSmsBlast,
              failActionCreator:    rejectSendSmsBlast,
              args:                 [smsBlastID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveSendSmsBlastAction
          const { smsBlast } = payload

          // Sendy-poo
          let byID = {
            ...state.byID,
            [smsBlast.id]: smsBlast
          }

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [smsBlast.id]: false,
              },
              successFlashMessage: 'SMS Blast successfully sendd!',
            },
            Cmd.list([
              Cmd.action(push('/sms-blasts')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectSendSmsBlastAction
          const { error, requestParams } = payload
          const { smsBlastID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [smsBlastID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [smsBlastID]: error,
            },
          }
        }
      }

      break
    }
    
    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }
  }

  // Default
  return state
}
