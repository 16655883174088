import Notification, { NotificationJSON } from 'src/model/Notification'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getNotifications = async (): Promise<Notification[]> => {
  // Build request
  const url = urlForEndpoint(`notifications`, {})
  const request = newRequest(HTTPMethods.GET, token())

  // Perform request
  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    const { notifications: notificationsJSON } = await parseResponse(response)

    let notifications: Notification[] = notificationsJSON.map((notificationJSON: NotificationJSON) => new Notification(notificationJSON))

    return notifications

  } catch (err) {
    throw new LoopError(err, {})
  }
}


export const postNotificationsRead = async (): Promise<void> => {
  // Build request
  const url = urlForEndpoint(`notifications/read`)

  const request = newRequest(HTTPMethods.POST, token())

  // Perform request
  try {
    // Fetch
    const response = await fetchWithErrors(url, request)
    await parseResponse(response)

  } catch (err) {
    throw new LoopError(err, { })
  }
}