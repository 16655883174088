import { LocationChangeAction, push, ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import PaymentAccount from '../../model/PaymentAccount'
import {
  CreatePaymentAccountAction,
  DeletePaymentAccountAction,
  FetchPaymentAccountByIDAction,
  FetchPaymentAccountsAction,
  PaymentAccountsActions,
  rejectCreatePaymentAccount,
  RejectCreatePaymentAccountAction,
  rejectDeletePaymentAccount,
  RejectDeletePaymentAccountAction,
  rejectFetchPaymentAccountByID,
  RejectFetchPaymentAccountByIDAction,
  rejectFetchPaymentAccounts,
  RejectFetchPaymentAccountsAction,
  rejectUpdatePaymentAccount,
  RejectUpdatePaymentAccountAction,
  resolveCreatePaymentAccount,
  ResolveCreatePaymentAccountAction,
  resolveDeletePaymentAccount,
  ResolveDeletePaymentAccountAction,
  resolveFetchPaymentAccountByID,
  ResolveFetchPaymentAccountByIDAction,
  resolveFetchPaymentAccounts,
  ResolveFetchPaymentAccountsAction,
  resolveUpdatePaymentAccount,
  ResolveUpdatePaymentAccountAction,
  UpdatePaymentAccountAction,
} from '../actions/paymentAccounts'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForPaymentAccountPage } from '../selectors/paymentAccounts'

interface IDMappedPaymentAccounts {
  [id: number]: PaymentAccount
}

interface PaymentAccountsReducerState {
  byID: IDMappedPaymentAccounts
  pages: { [hash: string]: PaymentAccountsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class PaymentAccountsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: PaymentAccountsReducerState = {
  byID: {},
  pages: {},
  isFetchingByID: {},
  successFlashMessage: null,
  errorByID: {},
}

export default function paymentAccountsReducer(
  state: PaymentAccountsReducerState = initialState,
  action: PaymentAccountsActions
): PaymentAccountsReducerState | Loop<PaymentAccountsReducerState> {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_ACCOUNTS: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as FetchPaymentAccountsAction
          const { sorting, page, limit, search } = payload

          const pageObject = new PaymentAccountsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          const pageKey = hashKeyForPaymentAccountPage(payload)

          return loop(
            {
              ...state,
              pages: {
                ...state.pages,
                [pageKey]: pageObject,
              },
            },
            Cmd.run(API.paymentAccounts.getPaymentAccounts, {
              successActionCreator: resolveFetchPaymentAccounts,
              failActionCreator: rejectFetchPaymentAccounts,
              args: [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveFetchPaymentAccountsAction
          const { paymentAccounts, paginationInfo, requestParams } = payload
          const pageKey = hashKeyForPaymentAccountPage(requestParams)

          const pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error: null,
            childIDs: paymentAccounts.map((paymentAccount: PaymentAccount) => paymentAccount.id),
            paginationInfo,
          }

          const idMappedPaymentAccounts: IDMappedPaymentAccounts = {}
          paymentAccounts.forEach(paymentAccount => {
            idMappedPaymentAccounts[paymentAccount.id] = paymentAccount
          })

          return {
            ...state,
            byID: { ...state.byID, ...idMappedPaymentAccounts },
            pages: {
              ...state.pages,
              [pageKey]: pageObject,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPaymentAccountsAction
          const { error, requestParams } = payload
          const pageKey = hashKeyForPaymentAccountPage(requestParams)

          return {
            ...state,
            pages: {
              ...state.pages,
              [pageKey]: {
                ...state.pages[pageKey],
                isFetching: false,
                error,
              },
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.FETCH_PAYMENT_ACCOUNT_BY_ID: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as FetchPaymentAccountByIDAction
          const { paymentAccountID } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentAccountID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [paymentAccountID]: null,
              },
            },
            Cmd.run(API.paymentAccounts.getPaymentAccountByID, {
              successActionCreator: resolveFetchPaymentAccountByID,
              failActionCreator: rejectFetchPaymentAccountByID,
              args: [paymentAccountID],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveFetchPaymentAccountByIDAction
          const { paymentAccount } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [paymentAccount.id]: paymentAccount,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccount.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPaymentAccountByIDAction
          const { error, requestParams } = payload
          const { paymentAccountID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccountID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [paymentAccountID]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.CREATE_PAYMENT_ACCOUNT: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as CreatePaymentAccountAction
          const { name, company, phoneNumber, emailAddress } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID: {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.paymentAccounts.postPaymentAccount, {
              successActionCreator: resolveCreatePaymentAccount,
              failActionCreator: rejectCreatePaymentAccount,
              args: [name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveCreatePaymentAccountAction
          const { paymentAccount } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [paymentAccount.id]: paymentAccount,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            successFlashMessage: 'Payment account created successfully',
          }
        }

        case false: {
          const { payload } = action as RejectCreatePaymentAccountAction
          const { error } = payload

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID: {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.UPDATE_PAYMENT_ACCOUNT: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as UpdatePaymentAccountAction
          const { paymentAccountID, name, company, phoneNumber, emailAddress } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentAccountID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [paymentAccountID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.paymentAccounts.putPaymentAccount, {
              successActionCreator: resolveUpdatePaymentAccount,
              failActionCreator: rejectUpdatePaymentAccount,
              args: [paymentAccountID, name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveUpdatePaymentAccountAction
          const { paymentAccount } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [paymentAccount.id]: paymentAccount,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccount.id]: false,
            },
            successFlashMessage: 'Payment account updated successfully',
          }
        }

        case false: {
          const { payload } = action as RejectUpdatePaymentAccountAction
          const { error, requestParams } = payload
          const { paymentAccountID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccountID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [paymentAccountID]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.DELETE_PAYMENT_ACCOUNT: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as DeletePaymentAccountAction
          const { paymentAccountID } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentAccountID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [paymentAccountID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.paymentAccounts.deletePaymentAccount, {
              successActionCreator: resolveDeletePaymentAccount,
              failActionCreator: rejectDeletePaymentAccount,
              args: [paymentAccountID],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveDeletePaymentAccountAction
          const { paymentAccount } = payload

          const { [paymentAccount.id]: deletedPaymentAccount, ...remainingPaymentAccounts } = state.byID
          const { [paymentAccount.id]: deletedError, ...remainingErrors } = state.errorByID
          const { [paymentAccount.id]: deletedFetching, ...remainingFetching } = state.isFetchingByID

          return {
            ...state,
            byID: remainingPaymentAccounts,
            errorByID: remainingErrors,
            isFetchingByID: remainingFetching,
            successFlashMessage: 'Payment account deleted successfully',
          }
        }

        case false: {
          const { payload } = action as RejectDeletePaymentAccountAction
          const { error, requestParams } = payload
          const { paymentAccountID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccountID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [paymentAccountID]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

    default: {
      return state
    }
  }
}
