
export default class Manager {
  id: number
  name: string
  emailAddress: string
  phoneNumber: string

  constructor(json: ManagerJSON) {
    this.id = json.id
    this.name = json.name
    this.emailAddress = json.emailAddress
    this.phoneNumber = json.phoneNumber
  }

  get [Symbol.toStringTag]() {
    return 'Manager'
  }
}

export interface ManagerJSON {
  id: number
  name: string
  emailAddress: string
  phoneNumber: string
}
