import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Payment from '../../model/Payment'
import {
  CreatePaymentAction,
  FetchPaymentByIDAction,
  FetchPaymentsAction,
  PaymentsActions,
  rejectCreatePayment,
  RejectCreatePaymentAction,
  rejectFetchPaymentByID,
  RejectFetchPaymentByIDAction,
  rejectFetchPayments,
  RejectFetchPaymentsAction,
  rejectUpdatePayment,
  RejectUpdatePaymentAction,
  resolveCreatePayment,
  ResolveCreatePaymentAction,
  resolveFetchPaymentByID,
  ResolveFetchPaymentByIDAction,
  resolveFetchPayments,
  ResolveFetchPaymentsAction,
  resolveUpdatePayment,
  ResolveUpdatePaymentAction,
  UpdatePaymentAction,
} from '../actions/payments'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForPaymentPage } from '../selectors/payments'

interface IDMappedPayments {
  [id: number]: Payment
}

interface PaymentsReducerState {
  byID: IDMappedPayments
  pages: { [hash: string]: PaymentsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class PaymentsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: PaymentsReducerState = {
  byID: {},
  pages: {},
  isFetchingByID: {},
  successFlashMessage: null,
  errorByID: {},
}

export default (state: PaymentsReducerState = initialState, action: PaymentsActions): PaymentsReducerState | Loop<PaymentsReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as FetchPaymentsAction
          const { sorting, page, limit, filters, search } = payload

          // Build page object
          const pageObject = new PaymentsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForPaymentPage(payload)

          return loop(
            {
              ...state,
              pages: {
                ...state.pages,
                [pageKey]: pageObject,
              },
            },
            Cmd.run(API.payments.getPayments, {
              successActionCreator: resolveFetchPayments,
              failActionCreator: rejectFetchPayments,
              args: [sorting, page, limit, filters, search],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveFetchPaymentsAction
          const { payments, paginationInfo, requestParams } = payload
          const pageKey = hashKeyForPaymentPage(requestParams)

          // Page object
          const pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error: null,
            childIDs: payments.map((payment: Payment) => payment.id),
            paginationInfo,
          }

          // Map payment ids to payments
          const idMappedPayments: IDMappedPayments = {}
          payments.forEach(payment => {
            idMappedPayments[payment.id] = payment
          })

          return {
            ...state,
            byID: { ...state.byID, ...idMappedPayments },
            pages: {
              ...state.pages,
              [pageKey]: pageObject,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPaymentsAction
          const { error, requestParams } = payload
          const pageKey = hashKeyForPaymentPage(requestParams)

          return {
            ...state,
            pages: {
              ...state.pages,
              [pageKey]: {
                ...state.pages[pageKey],
                isFetching: false,
                error,
              },
            },
          }
        }
      }
    }

    case actionTypes.FETCH_PAYMENT_BY_ID: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as FetchPaymentByIDAction
          const { paymentID } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [paymentID]: null,
              },
            },
            Cmd.run(API.payments.getPaymentByID, {
              successActionCreator: resolveFetchPaymentByID,
              failActionCreator: rejectFetchPaymentByID,
              args: [paymentID],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveFetchPaymentByIDAction
          const { payment } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [payment.id]: payment,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [payment.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPaymentByIDAction
          const { error, requestParams } = payload
          const { paymentID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [paymentID]: error,
            },
          }
        }
      }
    }

    case actionTypes.CREATE_PAYMENT: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as CreatePaymentAction

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID: {
                ...state.errorByID,
                [-1]: null,
              },
            },
            Cmd.run(API.payments.postPayment, {
              successActionCreator: resolveCreatePayment,
              failActionCreator: rejectCreatePayment,
              args: [payload],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveCreatePaymentAction
          const { payment } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [payment.id]: payment,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            successFlashMessage: 'Payment created successfully',
          }
        }

        case false: {
          const { payload } = action as RejectCreatePaymentAction
          const { error } = payload

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID: {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }
    }

    case actionTypes.UPDATE_PAYMENT: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as UpdatePaymentAction
          const { paymentID } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [paymentID]: null,
              },
            },
            Cmd.run(API.payments.putPayment, {
              successActionCreator: resolveUpdatePayment,
              failActionCreator: rejectUpdatePayment,
              args: [payload],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveUpdatePaymentAction
          const { payment } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [payment.id]: payment,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [payment.id]: false,
            },
            successFlashMessage: 'Payment updated successfully',
          }
        }

        case false: {
          const { payload } = action as RejectUpdatePaymentAction
          const { error, requestParams } = payload
          const { paymentID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [paymentID]: error,
            },
          }
        }
      }
    }

    case CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

    default: {
      return state
    }
  }
}
