import {
  VehicleByIDErrorResponse,
  VehiclesErrorResponse,
  VehiclesRequestParams,
  VehiclesResponse,
  PostVehicleErrorResponse,
  PostVehicleRequestParams,
  PutVehicleErrorResponse,
  PutVehicleRequestParams,
} from '../../api/vehicles'
import Vehicle, { VehicleFilters } from '../../model/Vehicle'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'


export interface FetchVehiclesAction {
  type: typeof types.FETCH_VEHICLES
  success: undefined
  payload: VehiclesRequestParams
}

export interface ResolveFetchVehiclesAction {
  type: typeof types.FETCH_VEHICLES
  success: boolean
  payload: {
    vehicles: Vehicle[]
    paginationInfo: PaginationInfo
    requestParams: VehiclesRequestParams
  }
}

export interface RejectFetchVehiclesAction {
  type: typeof types.FETCH_VEHICLES
  success: boolean
  payload: {
    error: Error
    requestParams: VehiclesRequestParams
  }
}

export interface FetchVehicleByIDAction {
  type: typeof types.FETCH_VEHICLE_BY_ID
  success: undefined
  payload: {
    vehicleID: number
  }
}

export interface ResolveFetchVehicleByIDAction {
  type: typeof types.FETCH_VEHICLE_BY_ID
  success: boolean
  payload: {
    vehicle: Vehicle
  }
}

export interface RejectFetchVehicleByIDAction {
  type: typeof types.FETCH_VEHICLE_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      vehicleID: number
    }
  }
}

export interface UpdateVehicleAction {
  type: typeof types.UPDATE_VEHICLE
  success: undefined
  payload: PutVehicleRequestParams
}

export interface ResolveUpdateVehicleAction {
  type: typeof types.UPDATE_VEHICLE
  success: boolean
  payload: {
    vehicle: Vehicle
  }
}

export interface RejectUpdateVehicleAction {
  type: typeof types.UPDATE_VEHICLE
  success: boolean
  payload: PutVehicleErrorResponse
}


export interface DeleteVehicleAction {
  type: typeof types.DELETE_VEHICLE
  success: undefined
  payload: {
    vehicleID: number
  }
}

export interface ResolveDeleteVehicleAction {
  type: typeof types.DELETE_VEHICLE
  success: boolean
  payload: {
    vehicle: Vehicle
  }
}

export interface RejectDeleteVehicleAction {
  type: typeof types.DELETE_VEHICLE
  success: boolean
  payload: VehicleByIDErrorResponse
}

export interface CreateVehicleAction {
  type: typeof types.CREATE_VEHICLE
  success: undefined
  payload: PostVehicleRequestParams
}

export interface ResolveCreateVehicleAction {
  type: typeof types.CREATE_VEHICLE
  success: boolean
  payload: {
    vehicle: Vehicle
  }
}

export interface RejectCreateVehicleAction {
  type: typeof types.CREATE_VEHICLE
  success: boolean
  payload: PostVehicleErrorResponse
}

export interface CreateVehicleAndLinkAction {
  type: typeof types.CREATE_VEHICLE_AND_LINK
  success: undefined
  payload: {
    licensePlate: string
    customerID: number
  }
}

export interface ResolveCreateVehicleAndLinkAction {
  type: typeof types.CREATE_VEHICLE_AND_LINK
  success: boolean
  payload: Vehicle
}

export interface RejectCreateVehicleAndLinkAction {
  type: typeof types.CREATE_VEHICLE_AND_LINK
  success: boolean
  payload: {
    error: Error
    requestParams: {
      licensePlate: string
      customerID: number
    }
  }
}

export const fetchVehicles = (sorting: string, page: number, limit: number, filters: VehicleFilters, search: string): FetchVehiclesAction => ({
  type:    types.FETCH_VEHICLES,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const resolveFetchVehicles = ({
                                        vehicles,
                                        paginationInfo,
                                        requestParams,
                                      }: VehiclesResponse): ResolveFetchVehiclesAction => ({
  type:    types.FETCH_VEHICLES,
  success: true,
  payload: {
    vehicles,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchVehicles = ({ error, requestParams }: VehiclesErrorResponse): RejectFetchVehiclesAction => ({
  type:    types.FETCH_VEHICLES,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchVehicleByID = (vehicleID: number): FetchVehicleByIDAction => ({
  type:    types.FETCH_VEHICLE_BY_ID,
  success: undefined,
  payload: {
    vehicleID,
  },
})

export const resolveFetchVehicleByID = (vehicle: Vehicle): ResolveFetchVehicleByIDAction => ({
  type:    types.FETCH_VEHICLE_BY_ID,
  success: true,
  payload: {
    vehicle,
  },
})

export const rejectFetchVehicleByID = ({
                                          error,
                                          requestParams,
                                        }: VehicleByIDErrorResponse): RejectFetchVehicleByIDAction => ({
  type:    types.FETCH_VEHICLE_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateVehicle = (params: PutVehicleRequestParams): UpdateVehicleAction => ({
  type:    types.UPDATE_VEHICLE,
  success: undefined,
  payload: params,
})

export const resolveUpdateVehicle = (vehicle: Vehicle): ResolveUpdateVehicleAction => ({
  type:    types.UPDATE_VEHICLE,
  success: true,
  payload: {
    vehicle,
  },
})

export const rejectUpdateVehicle = (payload: PutVehicleErrorResponse): RejectUpdateVehicleAction => ({
  type:    types.UPDATE_VEHICLE,
  success: false,
  payload,
})


export const deleteVehicle = (vehicleID: number): DeleteVehicleAction => ({
  type:    types.DELETE_VEHICLE,
  success: undefined,
  payload: {
    vehicleID,
  },
})

export const resolveDeleteVehicle = (vehicle: Vehicle): ResolveDeleteVehicleAction => ({
  type:    types.DELETE_VEHICLE,
  success: true,
  payload: {
    vehicle,
  },
})

export const rejectDeleteVehicle = (payload: VehicleByIDErrorResponse): RejectDeleteVehicleAction => ({
  type:    types.DELETE_VEHICLE,
  success: false,
  payload,
})


export const createVehicle = (params: PostVehicleRequestParams): CreateVehicleAction => ({
  type:    types.CREATE_VEHICLE,
  success: undefined,
  payload: params,
})

export const resolveCreateVehicle = (vehicle: Vehicle): ResolveCreateVehicleAction => ({
  type:    types.CREATE_VEHICLE,
  success: true,
  payload: {
    vehicle,
  },
})

export const rejectCreateVehicle = (payload: PostVehicleErrorResponse): RejectCreateVehicleAction => ({
  type:    types.CREATE_VEHICLE,
  success: false,
  payload,
})

export const createVehicleAndLink = (licensePlate: string, customerID: number): CreateVehicleAndLinkAction => ({
  type: types.CREATE_VEHICLE_AND_LINK,
  success: undefined,
  payload: {
    licensePlate,
    customerID
  }
})

export const resolveCreateVehicleAndLink = (vehicle: Vehicle): ResolveCreateVehicleAndLinkAction => ({
  type: types.CREATE_VEHICLE_AND_LINK,
  success: true,
  payload: vehicle,
})

export const rejectCreateVehicleAndLink = (error: Error, licensePlate: string, customerID: number): RejectCreateVehicleAndLinkAction => ({
  type: types.CREATE_VEHICLE_AND_LINK,
  success: false,
  payload: {
    error,
    requestParams: {
      licensePlate,
      customerID,
    },
  },
})

export type VehiclesActions =
  FetchVehiclesAction
  | RejectFetchVehiclesAction
  | ResolveFetchVehiclesAction
  | FetchVehicleByIDAction
  | ResolveFetchVehicleByIDAction
  | RejectFetchVehicleByIDAction
  | UpdateVehicleAction
  | RejectUpdateVehicleAction
  | ResolveUpdateVehicleAction
  | CreateVehicleAction
  | CreateVehicleAndLinkAction
  | ResolveCreateVehicleAndLinkAction
  | RejectCreateVehicleAndLinkAction
  | ResolveCreateVehicleAction
  | RejectCreateVehicleAction
  | DeleteVehicleAction
  | ResolveDeleteVehicleAction
  | RejectDeleteVehicleAction
  | ClearFlashMessagesAction
