import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'
import LoopError from '../store/errors/LoopError'

export interface CustomerImportRowData {
  licensePlate: string;
  make: string;
  model: string;
  name: string;
  phone: string;
  points: number;
  color: string;

  existingCustomerID?: number | null;
  existingCustomerName?: string | null;
  existingVehicleID?: number | null;
  existingLicensePlate?: string | null;
  existingMake?: string | null;
  existingModel?: string | null;
  existingColor?: string | null;

  createdTime: string;
  operation: string;
}

export interface ProductImportRowData {
  name: string;
  category: string;
  price: string;
  primaryVariantAttribute: string;
  primaryVariantValue: string;
  secondaryVariantAttribute: string;
  secondaryVariantValue: string;

  existingProductID?: number | null;
  existingName?: string | null;
  existingCategory?: string | null;
  existingPrice?: string | null;
  existingPrimaryVariantAttribute?: string | null;
  existingPrimaryVariantValue?: string | null;
  existingSecondaryVariantAttribute?: string | null;
  existingSecondaryVariantValue?: string | null;
  existingVariantID?: number | null;

  operation: string;
}

export type ImportRowData = CustomerImportRowData | ProductImportRowData;

export interface ImportRowError {
  invalidFields: string[];
  errors: string[];
}

export interface DataImport {
  id: number;
  merchantID: number;
  managerID: number;
  type: string;
  status: string;
  errorMessage?: string;
  createdAt: string;
  updatedAt: string;
  completedAt?: string;
  parsedAt?: string;
  importedAt?: string;
  rowDetails?: ImportRowData[];
  rowErrors?: ImportRowError[];
  rowCount: number;
  recordCreationCount: number;
  recordUpdateCount: number;
  recordDeletionCount: number;
}

export const getDataImportByID = async (id: string): Promise<DataImport> => {
  const url = urlForEndpoint(`data-imports/${id}`)
  const request = newRequest(HTTPMethods.GET, token())

  try {
    const response = await fetchWithErrors(url, request)
    const { dataImport } = await parseResponse(response)
    return dataImport
  } catch (err) {
    throw new LoopError(err, { id })
  }
}

export const postDataImport = async (file: File, type: string): Promise<DataImport> => {
  const url = urlForEndpoint(`data-imports/${type}`)
  const formData = new FormData()
  formData.append('file', file)

  const request = newRequest(HTTPMethods.POST, token())
  request.headers = new Headers({
    'Authorization': `Bearer ${token()}`
  })
  request.body = formData

  try {
    const response = await fetchWithErrors(url, request)
    const { dataImport } = await parseResponse(response)
    return dataImport
  } catch (err) {
    throw new LoopError(err, { fileName: file.name })
  }
}

export const startImport = async (id: string): Promise<DataImport> => {
  const url = urlForEndpoint(`data-imports/${id}/start`)
  const request = newRequest(HTTPMethods.POST, token())

  try {
    const response = await fetchWithErrors(url, request)
    const { dataImport } = await parseResponse(response)
    return dataImport
  } catch (err) {
    throw new LoopError(err, { id })
  }
} 