import { push, replace } from '@lagunovsky/redux-react-router'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Cmd, loop, Loop } from 'redux-loop'
import API from '../../api'
import {
  AuthenticationActionTypes,
  rejectSubmitAdminLogIn,
  RejectSubmitAdminLogInAction,
  rejectSubmitLoginForm,
  RejectSubmitLogInFormAction,
  resolveSubmitAdminLogIn,
  ResolveSubmitAdminLogInAction,
  resolveSubmitLoginForm,
  ResolveSubmitLogInFormAction,
  SubmitAdminLogInAction,
  SubmitLogInFormAction,
} from '../actions/authentication'
import { LOG_OUT, SUBMIT_ADMIN_LOG_IN, SUBMIT_LOG_IN_FORM } from '../constants/ActionTypes'

interface AuthenticationReducerState {
  token: string | null
  expiration: Date | null
  claims?: AuthClaims
  isAuthenticating: boolean
  error: Error | null
}

const initialState = (): AuthenticationReducerState => {
  const token = Cookies.get('token') || null
  // @ts-ignore
  let { name, emailAddress, merchantName } = decodeClaims(token || '')
  return {
    token,
    expiration:       null,
    claims:           { name, emailAddress, merchantName },
    isAuthenticating: false,
    error:            null,
  }
}

const decodeClaims = (token: string) => {
  if (token == null) {
    return {}
  }

  try {
    return jwtDecode(token)
  } catch (err) {
    return {}
  }
}

export default function authentication(state: AuthenticationReducerState = initialState(), action: AuthenticationActionTypes): AuthenticationReducerState | Loop<AuthenticationReducerState> {
  switch (action.type) {
    case SUBMIT_LOG_IN_FORM: {
      const { success } = action
      switch (success) {
        case undefined: {// Submitting
          let { emailAddress, password } = (action as SubmitLogInFormAction).payload
          return loop(
            {
              ...state,
              isAuthenticating: true,
              error:            null,
            },
            Cmd.run(API.auth.postLogIn, {
              args:                 [emailAddress, password],
              successActionCreator: resolveSubmitLoginForm,
              failActionCreator:    rejectSubmitLoginForm,
            }),
          )
        }

        case true: {
          let { token, expiration } = (action as ResolveSubmitLogInFormAction).payload

          // @ts-ignore
          let { name, emailAddress, merchantName } = decodeClaims(token || '')
          return loop(
            {
              ...state,
              isAuthenticating: false,
              token,
              expiration,
              claims:           { name, emailAddress, merchantName },
            },
            Cmd.run(saveTokenToCookie, {
              args: [token],
            }),
          )
        }

        case false: {
          let { error } = (action as RejectSubmitLogInFormAction).payload

          return {
            ...state,
            isAuthenticating: false,
            error,
          }
        }
      }
    }

    case SUBMIT_ADMIN_LOG_IN: {
      const { success } = action
      switch (success) {
        case undefined: {// Submitting
          let { managerID, token } = (action as SubmitAdminLogInAction).payload
          return loop(
            {
              ...state,
              isAuthenticating: true,
              error:            null,
            },
            Cmd.run(API.auth.postAdminLogIn, {
              args:                 [managerID, token],
              successActionCreator: resolveSubmitAdminLogIn,
              failActionCreator:    rejectSubmitAdminLogIn,
            }),
          )
        }

        case true: {
          let { token, expiration } = (action as ResolveSubmitAdminLogInAction).payload

          // @ts-ignore
          let { name, emailAddress, merchantName } = decodeClaims(token || '')
          return loop(
            {
              ...state,
              isAuthenticating: false,
              token,
              expiration,
              claims:           { name, emailAddress, merchantName },
            },
            Cmd.list([
              Cmd.run(saveTokenToCookie, {
                args: [token],
              }),
              Cmd.action(replace('/')),
              Cmd.run(reloadWindow)
            ]),
          )
        }

        case false: {
          let { error } = (action as RejectSubmitAdminLogInAction).payload

          return {
            ...state,
            isAuthenticating: false,
            error,
          }
        }
      }
    }

    case LOG_OUT: {
      return loop(
        {
          ...state,
          token:  null,
          claims: undefined,
        },
        Cmd.list([
          Cmd.run(removeTokenCookie),
          Cmd.action(push('/')),
        ]),
      )
    }

    default: {
      return state
    }
  }
}

function saveTokenToCookie(token: string) {
  Cookies.set('token', token, { expires: 365 })
}

function reloadWindow() {
  window.location.href = '/'
}

function removeTokenCookie() {
  Cookies.remove('token')
  location.reload()
}

interface AuthClaims {
  name: string
  emailAddress: string
  merchantName: string
}