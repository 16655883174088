import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import MerchantPointsTransaction from '../../model/MerchantPointsTransaction'
import {
  CreateMerchantPointsTransactionAction,
  DeleteMerchantPointsTransactionAction,
  FetchMerchantPointsTransactionByIDAction,
  FetchMerchantPointsTransactionsAction,
  MerchantPointsTransactionsActions,
  rejectCreateMerchantPointsTransaction,
  RejectCreateMerchantPointsTransactionAction,
  rejectDeleteMerchantPointsTransaction,
  RejectDeleteMerchantPointsTransactionAction,
  rejectFetchMerchantPointsTransactionByID,
  RejectFetchMerchantPointsTransactionByIDAction,
  rejectFetchMerchantPointsTransactions,
  RejectFetchMerchantPointsTransactionsAction,
  rejectUpdateMerchantPointsTransaction,
  RejectUpdateMerchantPointsTransactionAction,
  resolveCreateMerchantPointsTransaction,
  ResolveCreateMerchantPointsTransactionAction,
  resolveDeleteMerchantPointsTransaction,
  ResolveDeleteMerchantPointsTransactionAction,
  resolveFetchMerchantPointsTransactionByID,
  ResolveFetchMerchantPointsTransactionByIDAction,
  resolveFetchMerchantPointsTransactions,
  ResolveFetchMerchantPointsTransactionsAction,
  resolveUpdateMerchantPointsTransaction,
  ResolveUpdateMerchantPointsTransactionAction,
  UpdateMerchantPointsTransactionAction,
} from '../actions/merchantPointsTransactions'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForMerchantPointsTransactionPage } from '../selectors/merchantPointsTransactions'

interface IDMappedMerchantPointsTransactions {
  [id: number]: MerchantPointsTransaction
}

interface MerchantPointsTransactionsReducerState {
  byID: IDMappedMerchantPointsTransactions
  pages: { [hash: string]: MerchantPointsTransactionsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class MerchantPointsTransactionsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: MerchantPointsTransactionsReducerState = {
  byID: {},
  pages: {},
  isFetchingByID: {},
  successFlashMessage: null,
  errorByID: {},
}

export default function merchantPointsTransactionsReducer(
  state: MerchantPointsTransactionsReducerState = initialState,
  action: MerchantPointsTransactionsActions
): MerchantPointsTransactionsReducerState | Loop<MerchantPointsTransactionsReducerState> {
  switch (action.type) {
    case actionTypes.FETCH_MERCHANT_POINTS_TRANSACTIONS: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as FetchMerchantPointsTransactionsAction
          const { sorting, page, limit, search } = payload

          const pageObject = new MerchantPointsTransactionsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          const pageKey = hashKeyForMerchantPointsTransactionPage(payload)

          return loop(
            {
              ...state,
              pages: {
                ...state.pages,
                [pageKey]: pageObject,
              },
            },
            Cmd.run(API.merchantPoints.getMerchantPointsTransactions, {
              successActionCreator: resolveFetchMerchantPointsTransactions,
              failActionCreator: rejectFetchMerchantPointsTransactions,
              args: [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveFetchMerchantPointsTransactionsAction
          const { merchantPointsTransactions, paginationInfo, requestParams } = payload
          const pageKey = hashKeyForMerchantPointsTransactionPage(requestParams)

          const pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error: null,
            childIDs: merchantPointsTransactions.map((merchantPointsTransaction: MerchantPointsTransaction) => merchantPointsTransaction.id),
            paginationInfo,
          }

          const idMappedMerchantPointsTransactions: IDMappedMerchantPointsTransactions = {}
          merchantPointsTransactions.forEach(merchantPointsTransaction => {
            idMappedMerchantPointsTransactions[merchantPointsTransaction.id] = merchantPointsTransaction
          })

          return {
            ...state,
            byID: { ...state.byID, ...idMappedMerchantPointsTransactions },
            pages: {
              ...state.pages,
              [pageKey]: pageObject,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchMerchantPointsTransactionsAction
          const { error, requestParams } = payload
          const pageKey = hashKeyForMerchantPointsTransactionPage(requestParams)

          return {
            ...state,
            pages: {
              ...state.pages,
              [pageKey]: {
                ...state.pages[pageKey],
                isFetching: false,
                error,
              },
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.FETCH_MERCHANT_POINTS_TRANSACTION_BY_ID: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as FetchMerchantPointsTransactionByIDAction
          const { merchantPointsTransactionID } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [merchantPointsTransactionID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [merchantPointsTransactionID]: null,
              },
            },
            Cmd.run(API.merchantPoints.getMerchantPointsTransactionByID, {
              successActionCreator: resolveFetchMerchantPointsTransactionByID,
              failActionCreator: rejectFetchMerchantPointsTransactionByID,
              args: [merchantPointsTransactionID],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveFetchMerchantPointsTransactionByIDAction
          const { merchantPointsTransaction } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [merchantPointsTransaction.id]: merchantPointsTransaction,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransaction.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchMerchantPointsTransactionByIDAction
          const { error, requestParams } = payload
          const { merchantPointsTransactionID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransactionID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [merchantPointsTransactionID]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.CREATE_MERCHANT_POINTS_TRANSACTION: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as CreateMerchantPointsTransactionAction

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID: {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.merchantPoints.postMerchantPointsTransaction, {
              successActionCreator: resolveCreateMerchantPointsTransaction,
              failActionCreator: rejectCreateMerchantPointsTransaction,
              args: [payload],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveCreateMerchantPointsTransactionAction
          const { merchantPointsTransaction } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [merchantPointsTransaction.id]: merchantPointsTransaction,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            successFlashMessage: 'Merchant points transaction created successfully',
          }
        }

        case false: {
          const { payload } = action as RejectCreateMerchantPointsTransactionAction
          const { error } = payload

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID: {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.UPDATE_MERCHANT_POINTS_TRANSACTION: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as UpdateMerchantPointsTransactionAction
          const { merchantPointsTransactionID } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [merchantPointsTransactionID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [merchantPointsTransactionID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.merchantPoints.putMerchantPointsTransaction, {
              successActionCreator: resolveUpdateMerchantPointsTransaction,
              failActionCreator: rejectUpdateMerchantPointsTransaction,
              args: [payload],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveUpdateMerchantPointsTransactionAction
          const { merchantPointsTransaction } = payload

          return {
            ...state,
            byID: {
              ...state.byID,
              [merchantPointsTransaction.id]: merchantPointsTransaction,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransaction.id]: false,
            },
            successFlashMessage: 'Merchant points transaction updated successfully',
          }
        }

        case false: {
          const { payload } = action as RejectUpdateMerchantPointsTransactionAction
          const { error, requestParams } = payload
          const { merchantPointsTransactionID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransactionID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [merchantPointsTransactionID]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case actionTypes.DELETE_MERCHANT_POINTS_TRANSACTION: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as DeleteMerchantPointsTransactionAction
          const { merchantPointsTransactionID } = payload

          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [merchantPointsTransactionID]: true,
              },
              errorByID: {
                ...state.errorByID,
                [merchantPointsTransactionID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.merchantPoints.deleteMerchantPointsTransaction, {
              successActionCreator: resolveDeleteMerchantPointsTransaction,
              failActionCreator: rejectDeleteMerchantPointsTransaction,
              args: [merchantPointsTransactionID],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveDeleteMerchantPointsTransactionAction
          const { merchantPointsTransaction } = payload

          const { [merchantPointsTransaction.id]: deletedTransaction, ...remainingTransactions } = state.byID
          const { [merchantPointsTransaction.id]: deletedError, ...remainingErrors } = state.errorByID
          const { [merchantPointsTransaction.id]: deletedFetching, ...remainingFetching } = state.isFetchingByID

          return {
            ...state,
            byID: remainingTransactions,
            errorByID: remainingErrors,
            isFetchingByID: remainingFetching,
            successFlashMessage: 'Merchant points transaction deleted successfully',
          }
        }

        case false: {
          const { payload } = action as RejectDeleteMerchantPointsTransactionAction
          const { error, requestParams } = payload
          const { merchantPointsTransactionID } = requestParams

          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantPointsTransactionID]: false,
            },
            errorByID: {
              ...state.errorByID,
              [merchantPointsTransactionID]: error,
            },
          }
        }

        default:
          return state
      }
    }

    case CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

    default: {
      return state
    }
  }
}
