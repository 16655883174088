export default class Notification {
  id: number
  type: string
  title: string
  body: string
  createdAt: Date

  constructor(json: NotificationJSON) {
    this.id = json.id
    this.type = json.type
    this.title = json.title
    this.body = json.body
    this.createdAt = new Date(json.createdAt)
  }
}

export interface NotificationJSON {
  id: number
  type: string
  title: string
  body: string
  createdAt: string
}