import * as auth from './authentication'
import * as branches from './branches'
import * as categories from './categories'
import * as csvDownload from './csvDownload'
import * as dashboard from './dashboard'
import * as merchantPoints from './merchantPointsTransactions'
import * as notifications from './notification'
import * as payments from './payments'
import * as pointsProgram from './pointsProgram'
import * as productCredits from './productCredits'
import * as products from './products'
import * as bundles from './bundles'
import * as reports from './reports'
import * as smsBlasts from './smsBlasts'
import * as promos from './promos'
import * as customers from './customers'
import * as employees from './employees'
import * as partnerAgents from './partnerAgents'
import * as paymentAccounts from './paymentAccounts'
import * as productCreditTransactions from './productCreditTransactions'
import * as vehicles from './vehicles'
import * as invoices from './invoices'
import * as profile from './profile'
import * as whatsAppCreditTransactions from './whatsAppCreditTransactions'
import * as whatsAppSettings from './whatsAppSettings'
import * as dataImports from './dataImports'

// Organize API by domain/resource
const api = {
  auth,
  branches,
  categories,
  csvDownload,
  dashboard,
  merchantPoints,
  notifications,
  payments,
  pointsProgram,
  productCredits,
  products,
  bundles,
  reports,
  smsBlasts,
  promos,
  customers,
  employees,
  partnerAgents,
  paymentAccounts,
  productCreditTransactions,
  vehicles,
  invoices,
  profile,
  whatsAppCreditTransactions,
  whatsAppSettings,
  dataImports
}

export default api
