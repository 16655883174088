import { Cmd, loop, Loop } from 'redux-loop'
import API from '../../api'
import Dashboard from '../../model/Dashboard'
import {
  DashboardActions,
  fetchDashboard,
  FetchDashboardAction,
  rejectFetchDashboard,
  RejectFetchDashboardAction,
  resolveFetchDashboard,
  ResolveFetchDashboardAction,
  SetDashboardBranchAction,
  setDashboardPeriod,
  SetDashboardPeriodAction,
} from '../actions/dashboard'
import * as actionTypes from '../constants/ActionTypes'

interface DashboardReducerState {
  dashboard: Dashboard | null
  isFetching: boolean
  error: Error | null
  period: 'week' | 'month' | 'today' | 'last-week' | 'last-month' | 'year-to-date'
  branchID?: number
}

const initialState: DashboardReducerState = {
  dashboard: null,
  isFetching: false,
  error: null,
  period: 'today',
  branchID: undefined,
}

export default function dashboardReducer(
  state: DashboardReducerState = initialState,
  action: DashboardActions
): DashboardReducerState | Loop<DashboardReducerState> {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD: {
      const { success } = action
      switch (success) {
        case undefined: {
          const { payload } = action as FetchDashboardAction
          const { period, branchID } = payload

          return loop(
            {
              ...state,
              period,
              branchID,
              isFetching: true,
              error: null,
            },
            Cmd.run(API.dashboard.getDashboard, {
              successActionCreator: resolveFetchDashboard,
              failActionCreator: rejectFetchDashboard,
              args: [period, branchID],
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveFetchDashboardAction
          const { dashboard } = payload

          return {
            ...state,
            isFetching: false,
            dashboard,
            error: null,
          }
        }

        case false: {
          const { payload } = action as RejectFetchDashboardAction
          const { error } = payload

          return {
            ...state,
            isFetching: false,
            error,
            dashboard: null,
          }
        }

        default:
          return state
      }
    }

    case actionTypes.SET_DASHBOARD_PERIOD: {
      const { branchID } = state
      const { period } = action as SetDashboardPeriodAction

      return loop(
        {
          ...state,
          period,
        },
        Cmd.action(fetchDashboard(period, branchID)),
      )
    }

    case actionTypes.SET_DASHBOARD_BRANCH_ID: {
      const { period } = state
      const { branchID } = action as SetDashboardBranchAction

      return loop(
        {
          ...state,
          branchID: branchID || undefined,
        },
        Cmd.action(fetchDashboard(period, branchID || undefined)),
      )
    }

    default: {
      return state
    }
  }
}
