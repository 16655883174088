import { Cmd, loop, Loop } from 'redux-loop'
import API from '../../api'
import Branch from '../../model/Branch'
import Manager from '../../model/Manager'
import Merchant from '../../model/Merchant'
import {
  BranchesActions, rejectUpdateBranch,
  RejectUpdateBranchAction, resolveUpdateBranch,
  ResolveUpdateBranchAction,
  UpdateBranchAction,
} from '../actions/branches'
import {
  ChangePasswordAction,
  ProfileActions,
  rejectChangePassword, RejectChangePasswordAction,
  rejectFetchProfile,
  RejectFetchProfileAction,
  rejectUpdateProfile, RejectUpdateProfileAction,
  resolveChangePassword, ResolveChangePasswordAction,
  resolveFetchProfile,
  ResolveFetchProfileAction,
  resolveUpdateProfile, ResolveUpdateProfileAction,
  UpdateProfileAction,
} from '../actions/profile'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'

interface ProfileReducerState {
  merchant: Merchant | null
  manager: Manager | null
  branchesByID: Map<number, Branch> | null
  branchIsFetchingByID: Map<number, boolean>
  branchErrorByID: Map<number, Error>
  isFetching: boolean
  error: Error | null
  successFlashMessage?: string
}

const initialState: ProfileReducerState = {
  merchant: null,
  manager: null,
  branchesByID: null,
  branchIsFetchingByID: new Map<number, boolean>(),
  branchErrorByID: new Map<number, Error>(),
  isFetching: false,
  error: null,
}

export default function profile(state: ProfileReducerState = initialState, action: ProfileActions | BranchesActions): ProfileReducerState | Loop<ProfileReducerState> {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE: {
      switch (action.success) {
        case undefined: {
          return loop(
            {
              ...state,
              isFetching: true,
            },
            Cmd.run(API.profile.getProfile, {
              successActionCreator: resolveFetchProfile,
              failActionCreator:    rejectFetchProfile,
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchProfileAction
          const { profileResponse } = payload
          const { manager, merchant, branches } = profileResponse
          let branchesByID = new Map<number, Branch>
          branches.forEach(branch => branchesByID.set(branch.id, branch))

          return {
            ...state,
            manager,
            merchant,
            branchesByID,
            isFetching: false,
            error: null,
          }
        }

        case false: {
          const { payload } = action as RejectFetchProfileAction
          return {
            ...state,
            isFetching: false,
            error: payload.error,
          }
        }
      }
    }

    case actionTypes.UPDATE_PROFILE: {
      switch (action.success) {
        case undefined: {
          const { payload } = action as UpdateProfileAction
          return loop(
            {
              ...state,
              isFetching: true,
            },
            Cmd.run(API.profile.putManager, {
              args: [payload],
              successActionCreator: resolveUpdateProfile,
              failActionCreator:    rejectUpdateProfile,
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveUpdateProfileAction
          return {
            ...state,
            manager: payload.manager,
            isFetching: false,
            error: null,
            successFlashMessage: 'Profile updated successfully',
          }
        }

        case false: {
          const { payload } = action as RejectUpdateProfileAction
          return {
            ...state,
            isFetching: false,
            error: payload.error,
            successFlashMessage: undefined,
          }
        }
      }
    }

    case actionTypes.UPDATE_BRANCH: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateBranchAction
          const { branchID } = payload

          // Update branch fetching state
          let branchIsFetchingByID = new Map(state.branchIsFetchingByID)
          branchIsFetchingByID.set(branchID, true)

          return loop(
            {
              ...state,
              branchIsFetchingByID,
            },
            Cmd.run(API.branches.putBranch, {
              successActionCreator: resolveUpdateBranch,
              failActionCreator:    rejectUpdateBranch,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateBranchAction
          const { branch } = payload
          const { id } = branch

          // Update branch fetching state
          let branchIsFetchingByID = new Map(state.branchIsFetchingByID)
          branchIsFetchingByID.set(id, false)

          // Update branch error state
          let branchErrorByID = new Map(state.branchErrorByID)
          branchErrorByID.delete(id)

          // Update branch
          let branchesByID = new Map(state.branchesByID)
          branchesByID.set(id, branch)

          return {
            ...state,
            branchesByID,
            branchIsFetchingByID,
            branchErrorByID,
          }
        }

        case false: {
          let { payload } = action as RejectUpdateBranchAction
          const { error, requestParams } = payload
          const { branchID } = requestParams

          // Update branch fetching state
          let branchIsFetchingByID = new Map(state.branchIsFetchingByID)
          branchIsFetchingByID.set(branchID, false)

          // Update branch error state
          let branchErrorByID = new Map(state.branchErrorByID)
          branchErrorByID.set(branchID, error)

          return {
            ...state,
            branchIsFetchingByID,
            branchErrorByID,
          }
        }
      }
    }

    case actionTypes.CHANGE_PASSWORD: {
      switch (action.success) {
        case undefined: {
          const { payload } = action as ChangePasswordAction
          return loop(
            {
              ...state,
              isFetching: true,
            },
            Cmd.run(API.profile.postChangePassword, {
              args: [payload],
              successActionCreator: resolveChangePassword,
              failActionCreator:    rejectChangePassword,
            }),
          )
        }

        case true: {
          const { payload } = action as ResolveChangePasswordAction
          return {
            ...state,
            manager: payload.manager,
            isFetching: false,
            error: null,
            successFlashMessage: 'Password changed successfully',
          }
        }

        case false: {
          const { payload } = action as RejectChangePasswordAction
          return {
            ...state,
            isFetching: false,
            error: payload.error,
            successFlashMessage: undefined,
          }
        }
      }
    }

    case CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: undefined,
      }
    }

    default: {
      return state
    }
  }
}
