import { push, replace } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Bundle from '../../model/Bundle'
import {
  CreateBundleAction,
  DeleteBundleAction,
  FetchBundleByIDAction,
  FetchBundlesAction,
  BundlesActions,
  rejectCreateBundle,
  RejectCreateBundleAction,
  rejectDeleteBundle,
  RejectDeleteBundleAction,
  rejectFetchBundleByID,
  RejectFetchBundleByIDAction,
  rejectFetchBundles,
  RejectFetchBundlesAction,
  rejectUpdateBundle,
  RejectUpdateBundleAction,
  resolveCreateBundle,
  ResolveCreateBundleAction,
  resolveDeleteBundle,
  ResolveDeleteBundleAction,
  resolveFetchBundleByID,
  ResolveFetchBundleByIDAction,
  resolveFetchBundles,
  ResolveFetchBundlesAction,
  resolveUpdateBundle,
  ResolveUpdateBundleAction,
  UpdateBundleAction,
} from '../actions/bundles'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForBundlePage } from '../selectors/bundles'

export interface IDMappedBundles {
  [id: number]: Bundle
}

interface BundlesReducerState {
  byID: IDMappedBundles
  pages: { [hash: string]: BundlesReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class BundlesReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: BundlesReducerState = {
  byID:                  {},
  pages:                 {},
  isFetchingByID:        {},
  successFlashMessage:   null,
  errorByID:             {},
}

export default (state: BundlesReducerState = initialState, action: BundlesActions): BundlesReducerState | Loop<BundlesReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_BUNDLES: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchBundlesAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new BundlesReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForBundlePage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.bundles.getBundles, {
              successActionCreator: resolveFetchBundles,
              failActionCreator:    rejectFetchBundles,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchBundlesAction
          const { bundles, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForBundlePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       bundles.map((bundle: Bundle) => bundle.id),
            paginationInfo: paginationInfo,
          }

          // Map bundle ids to bundles
          let idMappedBundles: IDMappedBundles = {}
          bundles.forEach(bundle => {
            idMappedBundles[bundle.id] = bundle
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedBundles),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchBundlesAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForBundlePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_BUNDLE_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchBundleByIDAction
          const { bundleID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [bundleID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [bundleID]: null,
              },
            },
            Cmd.run(API.bundles.getBundleByID, {
              successActionCreator: resolveFetchBundleByID,
              failActionCreator:    rejectFetchBundleByID,
              args:                 [bundleID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchBundleByIDAction
          const { bundle } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [bundle.id]: bundle,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [bundle.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchBundleByIDAction
          const { error, requestParams } = payload
          const { bundleID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [bundleID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [bundleID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_BUNDLE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateBundleAction
          const {
                  bundleID,
                  name,
                  price,
                  productIDQuantities,
                } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [bundleID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [bundleID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.bundles.putBundle, {
              successActionCreator: resolveUpdateBundle,
              failActionCreator:    rejectUpdateBundle,
              args:                 [bundleID, name, price, productIDQuantities],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateBundleAction
          const { bundle } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [bundle.id]: bundle,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [bundle.id]: false,
              },
              successFlashMessage: 'Bundle successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateBundleAction
          const { error, requestParams } = payload
          const { bundleID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [bundleID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [bundleID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_BUNDLE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteBundleAction
          const { bundleID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [bundleID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [bundleID]: null,
              },
            },
            Cmd.run(API.bundles.deleteBundle, {
              successActionCreator: resolveDeleteBundle,
              failActionCreator:    rejectDeleteBundle,
              args:                 [bundleID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteBundleAction
          const { bundle } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[bundle.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [bundle.id]: false,
              },
              successFlashMessage: 'Bundle successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/bundles')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteBundleAction
          const { error, requestParams } = payload
          const { bundleID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [bundleID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [bundleID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_BUNDLE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateBundleAction
          const { name, price, productIDQuantities } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.bundles.postBundle, {
              successActionCreator: resolveCreateBundle,
              failActionCreator:    rejectCreateBundle,
              args:                 [name, price, productIDQuantities],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateBundleAction
          const { bundle } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [bundle.id]: bundle,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Bundle successfully created!',
            },
            Cmd.list([
              Cmd.action(replace(`/bundles/${bundle.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateBundleAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

  // Default
  return state
}
