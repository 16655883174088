import Customer, { CustomerFilters, CustomerJSON, CustomerStats, CustomerStatsJSON } from '../model/Customer'
import PaginationInfo from '../model/PaginationInfo'
import ProductCreditBalance, { ProductCreditBalanceJSON } from '../model/ProductCreditBalance'
import LoopError from '../store/errors/LoopError'
import { BaseApiClient, fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

// Legacy functions for backward compatibility
export const getCustomers = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: CustomerFilters, search: string): Promise<CustomersResponse> => {
  const response = await customerApi.list(sorting, page, limit, filters, search);

  // FIXME: Janky hack to prevent pagination flashing
  response.paginationInfo.totalValue = 0
  return {
    customers: response.items,
    paginationInfo: response.paginationInfo,
    requestParams: response.requestParams
  };
}

export const getCustomerIDs = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: CustomerFilters, search: string): Promise<CustomerIDsResponse> => {
  return customerApi.getCustomerIDs(sorting, page, limit, filters, search);
}

export const getCustomerByID = async (customerID: number): Promise<Customer> => {
  return customerApi.getCustomerById(customerID);
}

export const getCustomerStatsByID = async (customerID: number): Promise<CustomerStatsResponse> => {
  return customerApi.getCustomerStats(customerID);
}

export const putCustomer = async (customerID: number, name: string, phoneNumber: string, emailAddress: string | null, address: string | null, gender: string | null, dateOfBirth: string | null): Promise<Customer> => {
  return customerApi.updateCustomer(customerID, {
    name,
    phoneNumber,
    emailAddress,
    address,
    gender,
    dateOfBirth,
  });
}

export const deleteCustomer = async (customerID: number): Promise<Customer> => {
  return customerApi.deleteCustomer(customerID);
}

export const postCustomer = async (name: string, phoneNumber: string, emailAddress: string | null, address: string | null, gender: string | null, dateOfBirth: string | null): Promise<Customer> => {
  return customerApi.createCustomer({
    name,
    phoneNumber,
    emailAddress,
    address,
    gender,
    dateOfBirth,
  });
}

export const createCustomerAndLinkVehicle = async (phoneNumber: string, vehicleID: number, name: string = 'New Customer'): Promise<Customer> => {
  // Build request
  const url = urlForEndpoint(`customers`)
  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    name,
    phoneNumber,
    vehicleID
  })

  try {
    const response = await fetchWithErrors(url, request)
    const { customer } = await parseResponse(response)
    return new Customer(customer as CustomerJSON)
  } catch (err) {
    throw new LoopError(err, { phoneNumber, vehicleID })
  }
}

export const linkVehicleToCustomer = async (customerID: number, licensePlate: string): Promise<Customer> => {
  return customerApi.linkVehicle(customerID, licensePlate);
}

export const unlinkVehicle = async (customerID: number, vehicleID: number): Promise<Customer> => {
  return customerApi.unlinkVehicle(customerID, vehicleID);
}

// New CustomerApiClient implementation
class CustomerApiClient extends BaseApiClient {
  constructor() {
    super('customers');
  }

  async list(
    sorting: string = 'id',
    page: number = 1,
    limit: number = 30,
    filters: CustomerFilters,
    search: string
  ): Promise<{ items: Customer[]; paginationInfo: PaginationInfo; requestParams: any }> {
    return this.getList<Customer, CustomerFilters>(
      (json: CustomerJSON) => new Customer(json),
      { sorting, page, limit, filters, search }
    );
  }

  async getCustomerById(customerID: number): Promise<Customer> {
    return super.getById(
      customerID,
      (json: CustomerJSON) => new Customer(json)
    );
  }

  async getCustomerIDs(
    sorting: string = 'id',
    page: number = 1,
    limit: number = 30,
    filters: CustomerFilters,
    search: string
  ): Promise<CustomerIDsResponse> {
    // Prepare filters
    let flattenedFilters = this.flattenFilters(filters);

    // Build request
    const url = urlForEndpoint(`customer-ids`, {
      search,
      ...flattenedFilters,
    });
    const request = newRequest(HTTPMethods.GET, token());

    try {
      // Fetch
      const response = await fetchWithErrors(url, request);

      // Handle errors and return response
      const { customerIDs } = await parseResponse(response);

      return {
        customerIDs,
        requestParams: {
          sorting,
          page,
          limit,
          filters,
          search,
        },
      };
    } catch (err) {
      throw new LoopError(err, { sorting, page, limit, search });
    }
  }

  async getCustomerStats(customerID: number): Promise<CustomerStatsResponse> {
    // Build request
    const url = urlForEndpoint(`customers/${customerID}/stats`);
    const request = newRequest(HTTPMethods.GET, token());

    try {
      // Fetch
      const response = await fetchWithErrors(url, request);

      // Handle errors and return response
      const { stats, creditBalances: creditBalancesJSON, merchantPointsBalance } = await parseResponse(response);

      const creditBalances = (creditBalancesJSON as Array<ProductCreditBalanceJSON>).map(json => new ProductCreditBalance(json));

      return {
        stats: new CustomerStats(stats as CustomerStatsJSON),
        creditBalances,
        merchantPointsBalance
      };
    } catch (err) {
      throw new LoopError(err, { customerID });
    }
  }

  async createCustomer(payload: {
    name: string;
    phoneNumber: string;
    emailAddress: string | null;
    address: string | null;
    gender: string | null;
    dateOfBirth: string | null;
  }): Promise<Customer> {
    return super.create(
      payload,
      (json: CustomerJSON) => new Customer(json)
    );
  }

  async updateCustomer(
    customerID: number,
    payload: {
      name: string;
      phoneNumber: string;
      emailAddress: string | null;
      address: string | null;
      gender: string | null;
      dateOfBirth: string | null;
    }
  ): Promise<Customer> {
    return super.update(
      customerID,
      payload,
      (json: CustomerJSON) => new Customer(json)
    );
  }

  async deleteCustomer(customerID: number): Promise<Customer> {
    return super.delete(
      customerID,
      (json: CustomerJSON) => new Customer(json)
    );
  }

  async linkVehicle(customerID: number, licensePlate: string): Promise<Customer> {
    // Build request
    const url = urlForEndpoint(`customers/${customerID}/vehicles`);
    const request = newRequest(HTTPMethods.POST, token());
    request.body = JSON.stringify({
      licensePlate,
    });

    try {
      const response = await fetchWithErrors(url, request);
      const { customer } = await parseResponse(response);
      return new Customer(customer as CustomerJSON);
    } catch (err) {
      throw new LoopError(err, { customerID });
    }
  }

  async unlinkVehicle(customerID: number, vehicleID: number): Promise<Customer> {
    // Build request
    const url = urlForEndpoint(`customers/${customerID}/vehicles/${vehicleID}`);
    const request = newRequest(HTTPMethods.DELETE, token());

    try {
      const response = await fetchWithErrors(url, request);
      const { customer } = await parseResponse(response);
      return new Customer(customer as CustomerJSON);
    } catch (err) {
      throw new LoopError(err, { customerID, vehicleID });
    }
  }

  protected flattenFilters(filters: CustomerFilters): any {
    // Prepare filters
    let flattenedFilters = {
      lastPurchasedProduct: filters.lastPurchasedProduct,
      frequencyOfVisits: JSON.stringify(filters.frequencyOfVisits),
      numberOfVisits: JSON.stringify(filters.numberOfVisits),
    };
    
    if (filters.lastPurchasedProduct == null || filters.lastPurchasedProduct.length == 0) {
      // @ts-ignore
      delete flattenedFilters.lastPurchasedProduct;
    }
    if (filters.frequencyOfVisits == null) {
      // @ts-ignore
      delete flattenedFilters.frequencyOfVisits;
    }
    if (filters.numberOfVisits == null) {
      // @ts-ignore
      delete flattenedFilters.numberOfVisits;
    }
    
    return flattenedFilters;
  }
}

// Create a singleton instance
export const customerApi = new CustomerApiClient();

// Interfaces
export interface CustomerIDsResponse {
  customerIDs: number[]
  requestParams: CustomersRequestParams
}

export interface CustomerStatsResponse {
  stats: CustomerStats
  creditBalances: Array<ProductCreditBalance>
  merchantPointsBalance: number
}

export interface CustomersResponse {
  customers: Customer[]
  paginationInfo: PaginationInfo
  requestParams: CustomersRequestParams
}

export interface CustomersErrorResponse {
  error: Error
  requestParams: CustomersRequestParams
}

export interface CustomersRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
  filters: CustomerFilters
}

export interface CustomerByIDRequestParams {
  customerID: number
}

export interface CustomerByIDErrorResponse {
  error: Error
  requestParams: CustomerByIDRequestParams
}

export interface PostCustomerRequestParams {
  name: string
  phoneNumber: string
  emailAddress: string | null
  gender: string | null
  address: string | null
  dateOfBirth: string | null
}

export interface PostCustomerErrorResponse {
  error: Error
  requestParams: PostCustomerRequestParams
}

export interface PutCustomerRequestParams extends PostCustomerRequestParams {
  customerID: number
}

export interface PutCustomerErrorResponse {
  error: Error
  requestParams: PutCustomerRequestParams
}