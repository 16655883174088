import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import WhatsAppCreditTransaction from '../../model/WhatsAppCreditTransaction'
import {
  CreateWhatsAppCreditTransactionAction,
  DeleteWhatsAppCreditTransactionAction,
  FetchWhatsAppCreditTransactionByIDAction,
  FetchWhatsAppCreditTransactionsAction,
  WhatsAppCreditTransactionsActions,
  rejectCreateWhatsAppCreditTransaction,
  RejectCreateWhatsAppCreditTransactionAction,
  rejectDeleteWhatsAppCreditTransaction,
  RejectDeleteWhatsAppCreditTransactionAction,
  rejectFetchWhatsAppCreditTransactionByID,
  RejectFetchWhatsAppCreditTransactionByIDAction,
  rejectFetchWhatsAppCreditTransactions,
  RejectFetchWhatsAppCreditTransactionsAction,
  rejectUpdateWhatsAppCreditTransaction,
  RejectUpdateWhatsAppCreditTransactionAction,
  resolveCreateWhatsAppCreditTransaction,
  ResolveCreateWhatsAppCreditTransactionAction,
  resolveDeleteWhatsAppCreditTransaction,
  ResolveDeleteWhatsAppCreditTransactionAction,
  resolveFetchWhatsAppCreditTransactionByID,
  ResolveFetchWhatsAppCreditTransactionByIDAction,
  resolveFetchWhatsAppCreditTransactions,
  ResolveFetchWhatsAppCreditTransactionsAction,
  resolveUpdateWhatsAppCreditTransaction,
  ResolveUpdateWhatsAppCreditTransactionAction,
  UpdateWhatsAppCreditTransactionAction,
} from '../actions/whatsAppCreditTransactions'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForWhatsAppCreditTransactionPage } from '../selectors/whatsAppCreditTransactions'

interface IDMappedWhatsAppCreditTransactions {
  [id: number]: WhatsAppCreditTransaction
}

interface WhatsAppCreditTransactionsReducerState {
  byID: IDMappedWhatsAppCreditTransactions
  pages: { [hash: string]: WhatsAppCreditTransactionsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class WhatsAppCreditTransactionsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: WhatsAppCreditTransactionsReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: WhatsAppCreditTransactionsReducerState = initialState, action: WhatsAppCreditTransactionsActions): WhatsAppCreditTransactionsReducerState | Loop<WhatsAppCreditTransactionsReducerState> => {
  switch (action.type) {

    case actionTypes.FETCH_WHATSAPP_CREDIT_TRANSACTIONS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchWhatsAppCreditTransactionsAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new WhatsAppCreditTransactionsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForWhatsAppCreditTransactionPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.whatsAppCreditTransactions.getWhatsAppCreditTransactions, {
              successActionCreator: resolveFetchWhatsAppCreditTransactions,
              failActionCreator:    rejectFetchWhatsAppCreditTransactions,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchWhatsAppCreditTransactionsAction
          const { whatsAppCreditTransactions, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForWhatsAppCreditTransactionPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       whatsAppCreditTransactions.map((whatsAppCreditTransaction: WhatsAppCreditTransaction) => whatsAppCreditTransaction.id),
            paginationInfo: paginationInfo,
          }

          // Map whatsAppCreditTransaction ids to whatsAppCreditTransactions
          let idMappedWhatsAppCreditTransactions: IDMappedWhatsAppCreditTransactions = {}
          whatsAppCreditTransactions.forEach(whatsAppCreditTransaction => {
            idMappedWhatsAppCreditTransactions[whatsAppCreditTransaction.id] = whatsAppCreditTransaction
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedWhatsAppCreditTransactions),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchWhatsAppCreditTransactionsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForWhatsAppCreditTransactionPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_WHATSAPP_CREDIT_TRANSACTION_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchWhatsAppCreditTransactionByIDAction
          const { whatsAppCreditTransactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [whatsAppCreditTransactionID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [whatsAppCreditTransactionID]: null,
              },
            },
            Cmd.run(API.whatsAppCreditTransactions.getWhatsAppCreditTransactionByID, {
              successActionCreator: resolveFetchWhatsAppCreditTransactionByID,
              failActionCreator:    rejectFetchWhatsAppCreditTransactionByID,
              args:                 [whatsAppCreditTransactionID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchWhatsAppCreditTransactionByIDAction
          const { whatsAppCreditTransaction } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [whatsAppCreditTransaction.id]: whatsAppCreditTransaction,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [whatsAppCreditTransaction.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchWhatsAppCreditTransactionByIDAction
          const { error, requestParams } = payload
          const { whatsAppCreditTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [whatsAppCreditTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [whatsAppCreditTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_WHATSAPP_CREDIT_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateWhatsAppCreditTransactionAction
          const { whatsAppCreditTransactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [whatsAppCreditTransactionID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [whatsAppCreditTransactionID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.whatsAppCreditTransactions.putWhatsAppCreditTransaction, {
              successActionCreator: resolveUpdateWhatsAppCreditTransaction,
              failActionCreator:    rejectUpdateWhatsAppCreditTransaction,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateWhatsAppCreditTransactionAction
          const { whatsAppCreditTransaction } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [whatsAppCreditTransaction.id]: whatsAppCreditTransaction,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [whatsAppCreditTransaction.id]: false,
              },
              successFlashMessage: 'Partner agent successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateWhatsAppCreditTransactionAction
          const { error, requestParams } = payload
          const { whatsAppCreditTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [whatsAppCreditTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [whatsAppCreditTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_WHATSAPP_CREDIT_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteWhatsAppCreditTransactionAction
          const { whatsAppCreditTransactionID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [whatsAppCreditTransactionID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [whatsAppCreditTransactionID]: null,
              },
            },
            Cmd.run(API.whatsAppCreditTransactions.deleteWhatsAppCreditTransaction, {
              successActionCreator: resolveDeleteWhatsAppCreditTransaction,
              failActionCreator:    rejectDeleteWhatsAppCreditTransaction,
              args:                 [whatsAppCreditTransactionID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteWhatsAppCreditTransactionAction
          const { whatsAppCreditTransaction } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[whatsAppCreditTransaction.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [whatsAppCreditTransaction.id]: false,
              },
              successFlashMessage: 'Partner agent successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/product-credit-transactions')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteWhatsAppCreditTransactionAction
          const { error, requestParams } = payload
          const { whatsAppCreditTransactionID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [whatsAppCreditTransactionID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [whatsAppCreditTransactionID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_WHATSAPP_CREDIT_TRANSACTION: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateWhatsAppCreditTransactionAction

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.whatsAppCreditTransactions.postWhatsAppCreditTransaction, {
              successActionCreator: resolveCreateWhatsAppCreditTransaction,
              failActionCreator:    rejectCreateWhatsAppCreditTransaction,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateWhatsAppCreditTransactionAction
          const { whatsAppCreditTransaction } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [whatsAppCreditTransaction.id]: whatsAppCreditTransaction,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Partner agent successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/product-credit-transactions/${whatsAppCreditTransaction.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateWhatsAppCreditTransactionAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
