import Vehicle, { VehicleFilters, VehicleJSON } from '../model/Vehicle'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const linkCustomerToVehicle = async (vehicleID: number, phoneNumber: string): Promise<Vehicle> => {
  // Build request
  const url = urlForEndpoint(`vehicles/${vehicleID}/customers`)
  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    phoneNumber,
  })

  try {
    const response = await fetchWithErrors(url, request)
    const { vehicle } = await parseResponse(response)
    return new Vehicle(vehicle as VehicleJSON)
  } catch (err) {
    throw new LoopError(err, { vehicleID })
  }
}


// Create API client instance

export const getVehicles = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: VehicleFilters, search: string): Promise<VehiclesResponse> => {
  // Build request
  const url = urlForEndpoint(`vehicles`, {
    sorting,
    page,
    limit,
    search: search || '', // Ensure search param is included and handle null/undefined
  })
  
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { vehicles: vehiclesJSON, paginationInfo } = await parseResponse(response)

    let vehicles = vehiclesJSON.map((vehicleJSON: VehicleJSON) => new Vehicle(vehicleJSON))

    // FIXME: Janky hack to prevent pagination flashing
    paginationInfo.totalValue = 0

    return {
      vehicles,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        filters,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getVehicleByID = async (vehicleID: number): Promise<Vehicle> => {

  // Build request
  const url = urlForEndpoint(`vehicles/${vehicleID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { vehicle } = await parseResponse(response)
    return new Vehicle(vehicle as VehicleJSON)
  } catch (err) {
    throw new LoopError(err, { vehicleID })
  }

}

export const putVehicle = async (requestParams: PutVehicleRequestParams): Promise<Vehicle> => {
  let { vehicleID } = requestParams

  // Build request
  const url = urlForEndpoint(`vehicles/${vehicleID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    ...requestParams,
    vehicleID: undefined,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { vehicle } = await parseResponse(response)
    return new Vehicle(vehicle as VehicleJSON)
  } catch (err) {
    throw new LoopError(err, requestParams)
  }
}

export const deleteVehicle = async (vehicleID: number): Promise<Vehicle> => {
  // Build request
  const url = urlForEndpoint(`vehicles/${vehicleID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { vehicle } = await parseResponse(response)
    return new Vehicle(vehicle as VehicleJSON)
  } catch (err) {
    throw new LoopError(err, { vehicleID })
  }
}

export const postVehicle = async (requestParams: PostVehicleRequestParams): Promise<Vehicle> => {
  // Build request
  const url = urlForEndpoint(`vehicles`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify(requestParams)

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response

    const { vehicle } = await parseResponse(response)
    return new Vehicle(vehicle as VehicleJSON)
  } catch (err) {
    throw new LoopError(err, requestParams)
  }
}

export interface VehiclesResponse {
  vehicles: Vehicle[]
  paginationInfo: PaginationInfo
  requestParams: VehiclesRequestParams
}

export interface VehiclesErrorResponse {
  error: Error
  requestParams: VehiclesRequestParams
}

export interface VehiclesRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
  filters: VehicleFilters
}

export interface VehicleByIDRequestParams {
  vehicleID: number
}

export interface VehicleByIDErrorResponse {
  error: Error
  requestParams: VehicleByIDRequestParams
}

export interface PostVehicleRequestParams {
  licensePlate: string
  vin: string | null
  make: string | null
  modelName: string | null
  year: number | null
  color: string | null
  customerID?: number | null
}

export interface PostVehicleErrorResponse {
  error: Error
  requestParams: PostVehicleRequestParams
}

export interface PutVehicleRequestParams extends PostVehicleRequestParams {
  vehicleID: number
}

export interface PutVehicleErrorResponse {
  error: Error
  requestParams: PutVehicleRequestParams
}