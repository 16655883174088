export default class PaymentStatus {
  static Unpaid = new PaymentStatus('unpaid', 'Unpaid')
  static PartiallyPaid = new PaymentStatus('partially_paid', 'Partially Paid')
  static FullyPaid = new PaymentStatus('fully_paid', 'Fully Paid')
  static FullyRefunded = new PaymentStatus('fully_refunded', 'Fully Refunded')
  static Voided = new PaymentStatus('voided', 'Voided')
  // static PartiallyRefunded = new PaymentStatus('partially_refunded', 'Partially Refunded')

  id: string
  name: string

  constructor(id: string, name: string) {
    this.id = id
    this.name = name
  }

  static byID = (paymentStatusID: string): PaymentStatus | null => {
    console.log(paymentStatusID)

    switch (paymentStatusID) {
      case PaymentStatus.Unpaid.id:
        return PaymentStatus.Unpaid

      case PaymentStatus.PartiallyPaid.id:
        return PaymentStatus.PartiallyPaid

      case PaymentStatus.FullyPaid.id:
        return PaymentStatus.FullyPaid

      case PaymentStatus.FullyRefunded.id:
        return PaymentStatus.FullyRefunded

      // case PaymentStatus.PartiallyRefunded.id:
      //   return PaymentStatus.PartiallyRefunded

      default:
        return null
    }
  }

  static All = [PaymentStatus.Unpaid, PaymentStatus.PartiallyPaid, PaymentStatus.FullyPaid, PaymentStatus.FullyRefunded]
}