import Cookies from 'js-cookie'
import QueryString, { ParsedUrlQueryInput } from 'querystring'
import store from '../store'
import * as errors from './errors'
import LoopError from '../store/errors/LoopError'

const API_HOST = process.env.API_HOST

export const token = (): string | null => {
  const {authentication} = store.getState()
  return authentication.token
}

export const urlForEndpoint = (endpoint: string, params: null | ParsedUrlQueryInput = null): string => {
  if (params == null) {
    return API_HOST + '/v1/manager/' + endpoint
  } else {
    return API_HOST + '/v1/manager/' + endpoint + '?' + QueryString.stringify(params)
  }
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const newRequest = (method: HTTPMethods, authToken: string | null = null, contentType = 'application/json'): RequestInit => {
  const headers = new Headers()
  headers.append('Content-Type', contentType)
  if (authToken !== null) {
    headers.append('Authorization', 'Bearer ' + authToken)
  }

  // Return fetch request body
  return {
    method,
    headers,
  }
}

export const fetchWithErrors = async (url: string, request: RequestInit): Promise<Response> => {
  try {
    return await fetch(url, request)
  } catch (err) {
    console.error('Failed to fetch:', err)
    throw errors.SERVER_UNREACHABLE
  }
}

export const parseResponse = async (response: Response): Promise<any> => {
  // If not successful, throw JSON as response
  let responseStatusNumber = Number(response.status)
  if (responseStatusNumber == 401) {
    // Logged out
    // delete auth cookie and reload page
    Cookies.remove('token')
    location.reload()

  } else if (responseStatusNumber >= 400 && responseStatusNumber <= 599) {
    throw await response.json()
  }

  // Parse response
  let json
  try {
    json = await response.json()
  } catch (err) {
    console.error('Failed to fetch:', err)
    throw errors.INVALID_RESPONSE
  }

  // Handle empty JSON with prejudice
  if (json === null || json === undefined) {
    throw errors.INVALID_RESPONSE
  }

  return json
}

/**
 * Base API client to reduce code duplication across API functions
 */
export class BaseApiClient {
  protected resourcePath: string;

  constructor(resourcePath: string) {
    this.resourcePath = resourcePath;
  }

  /**
   * Get a list of resources
   */
  protected async getList<T, F = any>(
    transformFn: (data: any) => T,
    params: {
      sorting?: string;
      page?: number;
      limit?: number;
      filters?: F;
      search?: string;
      [key: string]: any;
    } = {}
  ): Promise<{ items: T[]; paginationInfo: any; requestParams: any }> {
    const { sorting = 'id', page = 1, limit = 30, filters = {}, search = '', ...otherParams } = params;
    
    // Prepare filters if needed
    const flattenedFilters = this.flattenFilters(filters);
    
    // Build request
    const url = urlForEndpoint(this.resourcePath, {
      sorting,
      page,
      limit,
      search,
      ...flattenedFilters,
      ...otherParams,
    });
    
    const request = newRequest(HTTPMethods.GET, token());
    
    try {
      // Fetch
      const response = await fetchWithErrors(url, request);
      
      // Parse response
      const data = await parseResponse(response);
      
      // Extract items using the resource name (e.g., 'customers', 'products')
      const resourceKey = Object.keys(data).find(key => 
        Array.isArray(data[key]) && key !== 'paginationInfo'
      ) || '';
      
      const items = resourceKey ? data[resourceKey].map(transformFn) : [];
      
      return {
        items,
        paginationInfo: data.paginationInfo,
        requestParams: {
          sorting,
          page,
          limit,
          filters,
          search,
          ...otherParams,
        },
      };
    } catch (err) {
      throw new LoopError(err, { sorting, page, limit, search, ...otherParams });
    }
  }

  /**
   * Get a single resource by ID
   */
  protected async getById<T>(
    id: number | string,
    transformFn: (data: any) => T,
    subPath: string = ''
  ): Promise<T> {
    // Build request
    const path = `${this.resourcePath}/${id}${subPath ? `/${subPath}` : ''}`;
    const url = urlForEndpoint(path);
    const request = newRequest(HTTPMethods.GET, token());
    
    try {
      // Fetch
      const response = await fetchWithErrors(url, request);
      
      // Parse response
      const data = await parseResponse(response);
      
      // Extract the resource using the singular form of the resource name
      const resourceKey = Object.keys(data).find(key => 
        typeof data[key] === 'object' && !Array.isArray(data[key])
      ) || '';
      
      return transformFn(resourceKey ? data[resourceKey] : data);
    } catch (err) {
      throw new LoopError(err, { id });
    }
  }

  /**
   * Create a new resource
   */
  protected async create<T, P = any>(
    payload: P,
    transformFn: (data: any) => T,
    subPath: string = ''
  ): Promise<T> {
    // Build request
    const path = `${this.resourcePath}${subPath ? `/${subPath}` : ''}`;
    const url = urlForEndpoint(path);
    const request = newRequest(HTTPMethods.POST, token());
    request.body = JSON.stringify(payload);
    
    try {
      // Fetch
      const response = await fetchWithErrors(url, request);
      
      // Parse response
      const data = await parseResponse(response);
      
      // Extract the resource using the singular form of the resource name
      const resourceKey = Object.keys(data).find(key => 
        typeof data[key] === 'object' && !Array.isArray(data[key])
      ) || '';
      
      return transformFn(resourceKey ? data[resourceKey] : data);
    } catch (err) {
      throw new LoopError(err, payload);
    }
  }

  /**
   * Update an existing resource
   */
  protected async update<T, P = any>(
    id: number | string,
    payload: P,
    transformFn: (data: any) => T,
    subPath: string = ''
  ): Promise<T> {
    // Build request
    const path = `${this.resourcePath}/${id}${subPath ? `/${subPath}` : ''}`;
    const url = urlForEndpoint(path);
    const request = newRequest(HTTPMethods.PUT, token());
    request.body = JSON.stringify(payload);
    
    try {
      // Fetch
      const response = await fetchWithErrors(url, request);
      
      // Parse response
      const data = await parseResponse(response);
      
      // Extract the resource using the singular form of the resource name
      const resourceKey = Object.keys(data).find(key => 
        typeof data[key] === 'object' && !Array.isArray(data[key])
      ) || '';
      
      return transformFn(resourceKey ? data[resourceKey] : data);
    } catch (err) {
      throw new LoopError(err, { id, ...payload });
    }
  }

  /**
   * Delete a resource
   */
  protected async delete<T>(
    id: number | string,
    transformFn: (data: any) => T
  ): Promise<T> {
    // Build request
    const url = urlForEndpoint(`${this.resourcePath}/${id}`);
    const request = newRequest(HTTPMethods.DELETE, token());
    
    try {
      // Fetch
      const response = await fetchWithErrors(url, request);
      
      // Parse response
      const data = await parseResponse(response);
      
      // Extract the resource using the singular form of the resource name
      const resourceKey = Object.keys(data).find(key => 
        typeof data[key] === 'object' && !Array.isArray(data[key])
      ) || '';
      
      return transformFn(resourceKey ? data[resourceKey] : data);
    } catch (err) {
      throw new LoopError(err, { id });
    }
  }

  /**
   * Helper method to flatten filters for API requests
   * Override this in specific API clients as needed
   */
  protected flattenFilters(filters: any): any {
    if (!filters || typeof filters !== 'object') {
      return {};
    }
    
    const result: Record<string, any> = {};
    
    Object.entries(filters).forEach(([key, value]) => {
      if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
        return;
      }
      
      if (typeof value === 'object' && !Array.isArray(value)) {
        result[key] = JSON.stringify(value);
      } else if (Array.isArray(value) && value.length > 0) {
        result[key] = value;
      } else {
        result[key] = value;
      }
    });
    
    return result;
  }
}
