import { createSelector } from 'reselect'
import { ProductsRequestParams } from '../../api/products'
import Product from '../../model/Product'
import { RootReducerState } from '../'
import { ProductsReducerPage } from '../reducers/products'

export const hashKeyForProductPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search,
                                   }: ProductsRequestParams): string => `${page},${limit},${sorting},${search || ''}`

export const productsSelector = (state: RootReducerState): { [id: number]: Product } => state.products.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.products.errorByID
const productIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.products.isFetchingByID
const productPageSelector = (state: RootReducerState, props: any): ProductsReducerPage => state.products.pages[hashKeyForProductPage(props)] || new ProductsReducerPage()
export const productFlashMessageSelector = (state: RootReducerState): string | null => state.products.successFlashMessage

// TODO: fix for react router
export const productIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.productID || -1
}

export const productWithIDFromProps = createSelector(
  [productsSelector, productIDFromPropsSelector],
  (products, productID) => products[productID] ,
)

export const errorForProductWithIDFromProps = createSelector(
  [errorsSelector, productIDFromPropsSelector],
  (products, productID) => products[productID],
)

export const isFetchingForProductWithIDFromProps = createSelector(
  [productIsFetchingByIDSelector, productIDFromPropsSelector],
  (products, productID) => products[productID] || false,
)

export const productsByPageSelector = createSelector(
  [productsSelector, productPageSelector],
  (products, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(productID => products[productID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [productPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [productPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [productPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [productPageSelector],
  (page) => page.error || null,
)

